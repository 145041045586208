<template>
  <div class="middle-box text-center loginscreen animated fadeInDown">
    <div>
      <div>

        <h1 class="logo-name">
          <img :src="educamLogo">
        </h1>

      </div>
      <h3>Wachtwoord vergeten</h3>
      <p>Geef hieronder uw email adres op en ontvang een nieuw wachtwoord.</p>
      <b-form @submit="onSubmit" class="m-t">
        <b-form-group
          id="email-group"
        >
          <b-form-input
            id="email"
            v-model="form.email"
            type="email"
            placeholder="Uw email adres"
            class="form-control"
            aria-required="true"
          ></b-form-input>
        </b-form-group>
        <b-button block full-width variant="primary" type="submit" class="m-b">Wachtwoord aanvragen</b-button>

        <router-link to="/" class="no-active-style"><small>Aanmelden?</small></router-link>
        <b-alert :show="showError" variant="danger" class="mt-2">Er is iets fout gegaan bij het aanvragen van een nieuw wachtwoord. Controleer uw email adres en probeer opnieuw.</b-alert>
        <b-alert :show="showSuccess" variant="success" class="mt-2">De aanvraag voor uw nieuw wachtwoord is gelukt. Je ontvangt dadelijk een email met uw nieuw wachtwoord.</b-alert>
      </b-form>
      <p class="m-t"> <small>Educam administratie paneel &copy; 2020</small> </p>
    </div>
  </div>
</template>

<script>

import EducamApi from '@/axios/educam-api.instance'
import Mailer from '@/mixins/Mailer'
import AuthToken from '@/modules/AuthToken'

const authToken = new AuthToken()

export default {
  name: 'ForgotPassword',
  mixins: [Mailer],
  data () {
    return {
      showError: false,
      showSuccess: false,
      educamLogo: require('./../../../assets/img/logo-2.svg'),
      form: {
        email: ''
      }
    }
  },
  methods: {
    onSubmit (evt) {
      evt.preventDefault()

      this.showError = false
      this.showSuccess = false

      const password = Math.random().toString(36).substring(2, 10)

      EducamApi.post('/local/forgot-password', {
        email: this.form.email,
        password: password
      }).then(async response => {
        const email = await this.composeEmail(process.env.VUE_APP_EMAIL_FORGOT_PASSWORD, [
          {
            needle: 'password',
            replacer: password
          }])

        await this.sendMail(this.form.email, this.form.email, process.env.VUE_APP_EMAIL_FROM, process.env.VUE_APP_EMAIL_FROM_NAME, email.subject, email.body)
        this.showSuccess = true
        this.form.email = ''
        authToken.clear()
      }).catch(err => {
        console.log(err)
        this.showError = true
      })
    }
  }
}
</script>

<style scoped>
.router-link-active, .mm-active > a.no-active-style:active {
  color:#007bff !important;
}
</style>
